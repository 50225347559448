<template lang="pug">
include ../../../../configs/template
div.row.text-start.pt-2
  div.w-100.p-2
    v-form( @submit.prevent="checkCoursePrice")
      div
        +date-picker-validation('dateStart', 'dateEffective', 'dateStartObject', '["required", "maxValue", "minValue"]')
      div
        +field-validation('price', 'price', '["required", "minValue"]')(type="number" step="0.01")
      div
        +select-validation('formType','formTypeList','priceForm','lang','["required"]')(item-value="value")
      div.w-100.text-center
        v-btn(color="success" type="submit" :loading="buttonLoader") {{$t('save')}}
</template>

<script>
import ValidationAlert from '@/components/atoms/FormComponents/ValidationAlert/ValidationAlert.vue'
import { maxValue, minValue, required } from 'vuelidate/lib/validators'
import { dateFormat } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeCoursePricesEdit',
  props: {
    documentSailor: { type: Object, default: () => ({}) }
  },
  components: {
    ValidationAlert
  },
  data () {
    return {
      formTypeList: [
        {
          id: 1,
          ua: 'Ф1 (грн.)',
          en: 'F1 (uah.)',
          value: 'First'
        },
        {
          id: 2,
          ua: 'Ф2 ($)',
          en: 'F2 ($)',
          value: 'Second'
        }
      ],
      formType: this.documentSailor.type_of_form === 'First'
        ? {
          id: 1,
          ua: 'Ф1 (грн.)',
          en: 'F1 (uah.)',
          value: 'First'
        }
        : {
          id: 2,
          ua: 'Ф2 ($)',
          en: 'F2 ($)',
          value: 'Second'
        },
      dateStart: this.documentSailor.date_start,
      course: this.documentSailor.course,
      price: this.documentSailor.price,
      dateTomorrow: null,
      buttonLoader: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      // mapping documents
      mappingCourses: state => state.directory.courses
    }),
    dateStartObject () {
      return this.dateStart ? new Date(this.dateStart) : null
    }
  },
  mounted () {
    // Get tomorrow's date
    let tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1)
    this.dateTomorrow = dateFormat(tomorrow)
  },
  validations () {
    return {
      // course: { required },
      price: { required, minValue: minValue(0) },
      formType: { required },
      dateStartObject: {
        required,
        minValue: minValue(new Date(this.dateTomorrow)),
        maxValue: maxValue(new Date('2200-01-01'))
      }
    }
  },
  methods: {
    ...mapActions(['patchCoursePriceOnline']),
    /** Check fields entries validation */
    checkCoursePrice () {
      if (this.$v.$invalid) {
        return this.$v.$touch()
      } else this.editCoursePrice()
    },

    /** Edit ETI course price */
    async editCoursePrice () {
      this.buttonLoader = true
      const body = {
        date_start: this.dateStart,
        course: this.course.id,
        price: parseFloat(this.price),
        type_of_form: this.formType
      }
      const { id } = this.$route.params
      const response = await this.patchCoursePriceOnline({ body, id })
      this.buttonLoader = false
      if (response.code === 200) {
        this.$notification.success('coursePriceEdited')
        response.data.behavior.viewEditBlock = false
        this.$parent.document = response.data
        // this.$store.commit('updateDataSailor', { type: 'backOfficeCoefficient', value: response.data })
      }
    }
  }
}

</script>
